/** @format */

import { Badge, Tooltip } from "antd";
import { Tab, Tabs } from "react-tabs-scrollable";

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "react-tabs-scrollable/dist/rts.css";
import LazyImage from "../../Pages/Stores/Restaurant/LazyImage.jsx";
import ProductLoadingSkeleton from "../Skeletons/ProductLoadingSkeleton.jsx";
import CreateHalfHalfItem from "./CreateHalfHalfItem.jsx";
import ProductDetails from "./ProductDetails.jsx";

const RenderDiscountBatch = ({ children, discount }) => {
  if (discount) {
    return (
      <Badge.Ribbon
        color="red"
        text={"- " + discount + " %"}
        style={{
          position: "absolute",
          right: "5px",
        }}
      />
    );
  } else {
    return children;
  }
};

const ProductListing = ({ checkOutOfStock, addToCartHandler }) => {
  const currencyCode = useSelector(
    (state) => state.storeReducer.storeData.currencyCode
  );
  const dispatch = useDispatch();
  const {
    allTemplateData,
    productByCategories,
    getProductByCategoryLoading,
  } = useSelector((state) => state.storeReducer);
  const [
    shownProductsWithCategories,
    setShownProductsWithCategories,
  ] = useState([]);
  const productsWithCategorie = useSelector(
    (state) => state.storeReducer.homePageData?.productsWithCategories
  );
  const { homePageData } = useSelector((state) => state.storeReducer);
  const [searchVal, setSearchVal] = React.useState("");
  const [activeCategory, setActiveCategory] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [activeSubCategoryTab, setActiveSubCategoryTab] = useState(0);
  const [activeSubCategory, setActiveSubCategory] = useState();

  const [activeFood, setActiveFood] = useState();
  const [isProductDetailModalOpen, setIsProductDetailModalOpen] = useState(
    false
  );

  // Half-Half
  const [isHalfHalfProduct, setIsHalfHalfProduct] = useState(false);
  const [isCreateHalfHalfItemOpen, setIsCreateHalfHalfItemOpen] = useState(
    false
  );
  const [groupedProducts, setGroupedProducts] = useState([]);
  const [
    toBeUpdateproductFromHalfHalf,
    setToBeUpdateProdcutFromHalfHalf,
  ] = useState(null);
  const [toBeUpdateHalfHalfIndex, setToBeUpdateHalfHalfIndex] = useState(null);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  useEffect(() => {
    if (productByCategories) {
      setShownProductsWithCategories(productByCategories);
    }
    // setActiveCategory(productsWithCategorie?.[0]?.categoryId);
  }, [productByCategories]);

  // useEffect(() => {
  //   dispatch({
  //     type: "STORE_SEARCH_KEYWORD",
  //     payload: searchVal,
  //   });
  //   setProductsWithCategories((prev) =>
  //     productsWithCategorie
  //       ?.map((item) => {
  //         return {
  //           ...item,
  //           products: item.products.filter((product) =>
  //             product.productName
  //               .toLowerCase()
  //               .includes(searchVal.toLowerCase())
  //           ),
  //         };
  //       })
  //       .filter((item) => item.products.length > 0)
  //   );
  // }, [searchVal]);

  useEffect(() => {
    if (activeCategory) {
      dispatch({
        type: "GET_PRODUCT_BY_CATEGORIES_REQUEST",
        payload: {
          CategoryId: activeCategory,
          tableId: query.get("TableId") ? query.get("TableId") : "",
        },
      });
    }
  }, [activeCategory]);

  // define a onClick function to bind the value on tab click
  const onTabClick = (e, index) => {
    setActiveTab(index);
    setActiveCategory(homePageData?.prodcutCategories?.[index]?.id);
  };

  const onSubCategoryTabClick = (e, index) => {
    setActiveSubCategoryTab(index);
    setActiveSubCategory(productByCategories?.[0]?.subCategories?.[index]);
  };

  const productClickHandler = (item, category) => {
    setActiveFood(item);
    setIsProductDetailModalOpen(true);
    if (category.isHalfCategory) {
      setIsHalfHalfProduct(true);
    } else {
      setIsHalfHalfProduct(false);
    }
  };

  useEffect(() => {
    if (activeSubCategory) {
      const newProductByCategories = productByCategories?.map((item, index) => {
        return {
          ...item,
          products: item?.products?.filter(
            (product) =>
              product.categoryId.toLowerCase() ==
              activeSubCategory.id.toLowerCase()
          ),
        };
      });
      setShownProductsWithCategories(newProductByCategories);
    }
  }, [activeSubCategory]);

  useEffect(() => {
    if (productByCategories) {
      const subCat = productByCategories?.[0]?.subCategories?.[0];
      setActiveSubCategory(subCat);
      setActiveSubCategoryTab(0);
    }
  }, [productByCategories]);

  return (
    <div className="main-body">
      <div className="bakery">
        <div className="row">
          <div className="col-md-12">
            <Modal
              backdrop="static"
              className="product-detail-modal"
              show={isProductDetailModalOpen}
              onHide={() => {
                setIsProductDetailModalOpen(false);
              }}
              footer={null}
              size="xl"
            >
              <Modal.Header closeButton>
                <h5 class="modal-title text-red fw-bold" id="foodModalLabel">
                  {activeFood?.productName}
                </h5>
              </Modal.Header>

              <ProductDetails
                activeFood={activeFood}
                setActiveFood={setActiveFood}
                setIsProductDetailModalOpen={setIsProductDetailModalOpen}
                isHalfHalfProduct={isHalfHalfProduct}
                setIsHalfHalfProduct={setIsHalfHalfProduct}
                groupedProducts={groupedProducts}
                setGroupedProducts={setGroupedProducts}
                setIsCreateHalfHalfItemOpen={setIsCreateHalfHalfItemOpen}
              />
            </Modal>
            {isCreateHalfHalfItemOpen && (
              <CreateHalfHalfItem
                isCreateHalfHalfItemOpen={isCreateHalfHalfItemOpen}
                setIsCreateHalfHalfItemOpen={setIsCreateHalfHalfItemOpen}
                groupedProducts={groupedProducts}
                setGroupedProducts={setGroupedProducts}
                setIsHalfHalfProduct={setIsHalfHalfProduct}
              />
            )}
            <div className="stick-tab sticky-top mb-2">
              <Tabs activeTab={activeTab} onTabClick={onTabClick}>
                {homePageData?.prodcutCategories?.map((item, index) => {
                  return <Tab key={item.id}>{item.name}</Tab>;
                })}
              </Tabs>
              {productByCategories?.[0]?.subCategories?.length > 0 && (
                <div className="subcategory tab">
                  <Tabs
                    activeTab={activeSubCategoryTab}
                    onTabClick={onSubCategoryTabClick}
                  >
                    {productByCategories?.[0]?.subCategories?.map(
                      (item, index) => {
                        return <Tab key={item.id}>{item.name}</Tab>;
                      }
                    )}
                  </Tabs>
                </div>
              )}
            </div>

            {getProductByCategoryLoading ? (
              <ProductLoadingSkeleton />
            ) : (
              <section className="pt-3 mb-5 mb-md-0">
                <div className="row">
                  <div className="col-md-12">
                    <div className="">
                      <div className="row">
                        <div className={"col-md-12"}>
                          {/* Tab panes */}
                          <div className="tab-content restro-content">
                            {shownProductsWithCategories &&
                              shownProductsWithCategories?.map(
                                (category, index) => {
                                  return (
                                    <div
                                      className=" fooditems"
                                      id={category.categoryId}
                                      key={category.categoryId}
                                    >
                                      <div className="row row-cols-2 row-cols-lg-4 row-cols-xxl-4 g-3">
                                        {category.products.map(
                                          (item, index) => {
                                            if (
                                              item?.productVariations?.length >
                                              0
                                            ) {
                                              return (
                                                <div
                                                  className="col"
                                                  key={"productsKey" + index}
                                                >
                                                  <div className="card border-0  rounded-3 product-card h-100">
                                                    <div className="row ">
                                                      <RenderDiscountBatch
                                                        discount={
                                                          item.productVariations?.find(
                                                            (item) =>
                                                              item.isDefault
                                                          )?.discount
                                                        }
                                                      >
                                                        <div
                                                          className="product-image ratio"
                                                          onClick={() => {
                                                            productClickHandler(
                                                              item,
                                                              category
                                                            );
                                                          }}
                                                        >
                                                          <LazyImage
                                                            src={
                                                              item.productImage
                                                            }
                                                            alt="product-img"
                                                            className="img-fluid full-width"
                                                            placeholder={
                                                              "https://via.placeholder.com/300x300.png?text=Loading..."
                                                            }
                                                          />
                                                        </div>
                                                      </RenderDiscountBatch>

                                                      <span className="badge text-danger calories">
                                                        {item.productVariations?.find(
                                                          (item) =>
                                                            item.isDefault
                                                        ).calories &&
                                                          item.productVariation?.find(
                                                            (item) =>
                                                              item.isDefault
                                                          ).calories +
                                                            " Calories"}
                                                      </span>
                                                      <div className="card-body">
                                                        <h6 className="card-title fw-bold d-flex justify-content-between">
                                                          <a
                                                            onClick={() => {
                                                              productClickHandler(
                                                                item,
                                                                category
                                                              );
                                                            }}
                                                          >
                                                            {item.productName
                                                              ?.length > 60 ? (
                                                              <Tooltip
                                                                title={
                                                                  item.productName
                                                                }
                                                              >
                                                                {item.productName?.substring(
                                                                  0,
                                                                  60
                                                                ) + "..."}
                                                              </Tooltip>
                                                            ) : (
                                                              item.productName?.substring(
                                                                0,
                                                                60
                                                              )
                                                            )}
                                                          </a>
                                                          {!category.isHalfCategory && (
                                                            <Tooltip title="Add To Cart">
                                                              <div
                                                                // onClick={() => {
                                                                //   if (
                                                                //     checkOutOfStock(
                                                                //       item
                                                                //     )
                                                                //       ?.isOutOfStock
                                                                //   ) {
                                                                //     dispatch(
                                                                //       openNotificationWithIcon(
                                                                //         "error",
                                                                //         "Out of Order.Please click item for other variations of product "
                                                                //       )
                                                                //     );
                                                                //     return;
                                                                //   }
                                                                //   addToCartHandler(
                                                                //     item
                                                                //   );
                                                                // }}
                                                                onClick={() => {
                                                                  productClickHandler(
                                                                    item,
                                                                    category
                                                                  );
                                                                }}
                                                                className="tags"
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                              >
                                                                <i className="fas fa-shopping-cart fs-base ms-1 text-danger" />
                                                              </div>
                                                            </Tooltip>
                                                          )}
                                                        </h6>
                                                        <p className="text-light-white">
                                                          {item
                                                            .productDescription
                                                            ?.length > 20 ? (
                                                            <div
                                                              dangerouslySetInnerHTML={{
                                                                __html:
                                                                  item.productDescription?.substring(
                                                                    0,
                                                                    25
                                                                  ) + "...",
                                                              }}
                                                            />
                                                          ) : (
                                                            <div
                                                              dangerouslySetInnerHTML={{
                                                                __html: item.productDescription?.substring(
                                                                  0,
                                                                  25
                                                                ),
                                                              }}
                                                            />
                                                          )}
                                                        </p>
                                                        <div className="d-flex align-items-center justify-content-between mt-2">
                                                          <p className="aud">
                                                            {currencyCode}{" "}
                                                            {
                                                              item.productVariations?.find(
                                                                (item) =>
                                                                  item.isDefault
                                                              ).price
                                                            }
                                                            {item.productVariations?.find(
                                                              (item) =>
                                                                item.isDefault
                                                            ).actualPrice !==
                                                              item.productVariations?.find(
                                                                (item) =>
                                                                  item.isDefault
                                                              ).price && (
                                                              <span class="text-stroke">
                                                                <s>
                                                                  {" "}
                                                                  {
                                                                    currencyCode
                                                                  }{" "}
                                                                  {
                                                                    item.productVariations?.find(
                                                                      (item) =>
                                                                        item.isDefault
                                                                    )
                                                                      .actualPrice
                                                                  }
                                                                </s>{" "}
                                                              </span>
                                                            )}
                                                            <span className="text-muted fw-normal">
                                                              <small>
                                                                {" "}
                                                                {item.productVariations?.find(
                                                                  (item) =>
                                                                    item.isDefault
                                                                ).name
                                                                  ? "(" +
                                                                    item.productVariations?.find(
                                                                      (item) =>
                                                                        item.isDefault
                                                                    ).name +
                                                                    ")"
                                                                  : ""}
                                                              </small>
                                                            </span>
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          }
                                        )}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ProductListing);
